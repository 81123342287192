import { get } from "lodash-es";

import { Container } from "components/container";

import HeadingWrapper from "components/wrapper/components/heading";

import { Card } from "components/card";
import { ShowMoreText } from "components/show-more-text";
import { getEdgeSettings } from "components/wrapper/components";
import { entitiesConfig } from "config/entity";
import {
  LabelTitle,
  WithResolvedUrl,
  WrapperComponentProps,
} from "constants/types";
import { useLabels } from "helpers/hooks";
import { getSpeakingId, sanitize, unescape } from "helpers/text-processing";
import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { Im } from "types";

export type Selected = WithResolvedUrl<{
  caption?: LabelTitle;
  excerpt?: string;
  eye_catcher?: Im[];
  featured_logos?: Im[];
  id?: string;
  key_visual?: Im[];
  slug?: string;
  subtitle?: string;
  title?: string;
}>;

interface PickEntityWrapperProps extends WrapperComponentProps {
  select_entities?: Selected[];
}

export const PickEntityWrapper: FC<PickEntityWrapperProps> = (props) => {
  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={anchorNavId ? anchorNavId : `component__pick-entity-${props.index}`}
      className="section component__pick-entity"
    >
      <HeadingWrapper {...props} />
      <Row className={`entity-grid`}>
        {props.select_entities.filter(Boolean).map((row) => {
          const url = row?._url;
          const [entityType] = /^(\w+)/g.exec(row?.id);
          const callToActionUi =
            entitiesConfig[entityType].call_to_action_button._default;
          const [callToActionButton] = useLabels([callToActionUi, "more"]);
          return (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              className={`mb-4`}
              key={row.id}
              id={getSpeakingId({ title: row.title, slug: row.slug })}
            >
              <Card
                title={row.title}
                cardLabel={
                  row?.eye_catcher?.length > 0 && {
                    title: row.eye_catcher[0].label,
                    variant: "secondary",
                    position: "left",
                  }
                }
                featuredLogos={
                  row.featured_logos?.length > 0 &&
                  row.featured_logos.slice(0, 2).map((featuredLogo) => ({
                    media: featuredLogo,
                    width: 80,
                    height: 60,
                  }))
                }
                actionButtons={[
                  {
                    label: callToActionButton.title,
                    url,
                    variant: "primary",
                  },
                ]}
                cardImg={
                  row?.key_visual?.length > 0 && {
                    media: row.key_visual?.[0],
                    ar: "ar43",
                    background: "grey-triangle",
                    width: 430,
                    height: 323,
                  }
                }
                variant="vertical"
                url={url}
              >
                <div>
                  <p className="h6">{unescape(row.subtitle)}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(unescape(row.excerpt)),
                    }}
                  ></div>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

interface PickImageWrapperProps extends WrapperComponentProps {
  caption?: string;
  overwrite_caption?: boolean;
  select_images?: Selected[];
  show_image_caption?: boolean;
}

export const PickImageWrapper: FC<PickImageWrapperProps> = (props) => {
  const captions = props.overwrite_caption
    ? props.caption?.split("\n").map((caption) => ({ title: caption }))
    : null;

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={`component__pick-entity-${props.index}`}
      className="section component__pick-entity"
    >
      <HeadingWrapper {...props} />
      <Row className={`entity-grid`}>
        {props.select_images.map((row, index) => {
          const url = row._url;
          return (
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={4}
              xl={4}
              className={`mb-4`}
              key={row.id}
            >
              <Card
                title={row.title}
                cardImg={{
                  media: row,
                  ar: "ar43",
                  width: 400,
                  height: 300,
                }}
                cardLabel={
                  props.show_image_caption
                    ? props.overwrite_caption
                      ? captions[index]
                      : row.caption
                    : null
                }
                variant="vertical"
                url={url}
              >
                <div>
                  <p className="h6">{unescape(row.subtitle)}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(unescape(row.excerpt)),
                    }}
                  ></div>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export const PickTextblockWrapper: FC<WrapperComponentProps> = (props) => {
  const border = get(props, "edge_border_bottom", false); // shouldnt it be border property?
  const title = get(
    props,
    "slide_entity_title.0.title",
    get(props, "select_textblock.0.title", ""),
  );
  const text = get(props, "select_textblock.0.text_body");

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={`component__pick-textblock-${props.index}`}
      className="section component__pick-textblock"
    >
      <HeadingWrapper
        title={title}
        level="h3"
        styling_level={"h4"}
        border={border}
      />
      <div className="section">
        <ShowMoreText content={text} />
      </div>
    </Container>
  );
};
